/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Slider from "react-slick";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import innerCover from "assets/images/banners/moot-court.webp";
import mootCourt1 from "assets/images/moot-court-1.webp";
import mootCourt2 from "assets/images/moot-court-2.webp";
import mootCourt3 from "assets/images/moot-court-3.webp";
import mootCourt4 from "assets/images/moot-court-4.webp";
import mootCourt5 from "assets/images/moot-court-5.webp";
import mootCourt6 from "assets/images/moot-court-6.webp";
import mootCourt7 from "assets/images/moot-court-7.webp";
import mootCourt8 from "assets/images/moot-court-8.webp";
import mootCourt9 from "assets/images/moot-court-9.webp";
import mootCourt10 from "assets/images/moot-court-10.webp";
import mootCourt11 from "assets/images/moot-court-11.webp";

const images = [
  mootCourt1,
  mootCourt2,
  mootCourt3,
  mootCourt4,
  mootCourt5,
  mootCourt6,
  mootCourt7,
  mootCourt8,
  mootCourt9,
  mootCourt10,
  mootCourt11,
];

const settings = {
  dots: true,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function MootCourt() {
  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${innerCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Moot Court
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: { xs: 4, lg: 8 },
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <div className="row ">
          <div className="content">
            <Slider {...settings} className="library-slider left-side1">
              {images.slice().reverse().map((src, index) => (
                <img key={index} src={src} className="img-fluid" alt={`Moot Court ${index + 1}`} />
              ))}
            </Slider>
            <MKTypography variant="subtitle1" className="text-j">
              Students of Sree Narayana Law College, Poothotta, Ernakulam have always actively
              participated in moot court competitions organized by law colleges all over India. From
              the very inception of the college in 2012, the Moot Court Club of the college was
              formed and it has improved the intellectual and reasoning ability of the students so
              that they imbibe the qualities of a good lawyer. Every year, student teams for the
              competitions are selected after conducting internal competitions. Some of the national
              moot court competitions in which our students participated are 1ST National Online
              Moot Court Competition 2020 by School of Law, Ansal University Gurugram, T. S
              Venkateswara Iyer Memorial Moot Court Competition, 18th All India Moot Court
              Competition by Govt Law College Ernakulam 2018, National Trial Advocacy Moot
              Competition 2017 by Govt Law College Ernakulam, National Moot Court Competition 2017
              by Law College Ernakulam, National Trial Advocacy Moot Court Competition 2016 by
              Government Law College, Ernakulam, Justice T Ramachandran memorial ever rolling Trophy
              National Moot Court Competition 2015 by Thrissur Law College, Tenth All India Moot
              Court Competition 2015 by Government Law College Trivandrum, 9th All India Human
              Rights Moot Court Competition 2014 by SILT, Kottayam, National Moot Court Competition
              2014 by Government Law College Thrissur, National Trial Advocacy and Moot Court
              Competition 2014 by Government Law College Thrissur etc.Few of our teams have also
              bagged prizes. The moot court club is a team of dedicated students and faculty which
              strives to improve the mooting skills of students. Discussions on recent and relevant
              cases are done on a regular basis. Students of the club have a what Sapp group in
              which they discuss the developments in law. The club also motivates and imparts
              training to the fresh batches every year. The committed faculty and supporting library
              staff is the backbone of all activities of this club.
            </MKTypography>
          </div>
        </div>
      </Card>
    </>
  );
}

export default MootCourt;
